.mapboxgl {
    &-ctrl-attrib, &-ctrl-logo {
        display: none !important;
    }
    
    &-popup-content {
        padding: 0;
        background: none;
        box-shadow: none;
    }
}
