html, body, #root {
    min-height: 100%;
    height: 100%;
    
    * {
        transition: all .3s ease;
    }
    
    .mapboxgl-map, 
    .mapboxgl-marker, .mapboxgl-marker-anchor-center,
    .popup-root {
        &, & * {
            transition: none;
        }
    }
}

#root > .react-reveal {
    min-height: 100%;
    height: 100%;
}

hr {
    @apply border-t-2 border-gray-200;
}