.popup-content {
    box-shadow: 0px 0px 4px 4px #0000000f;
    background-color: #fff;
    @apply rounded-lg;
    
    .popup-arrow {
        filter: drop-shadow(0px 0px 6px rgb(0 0 0 / 5%));
    }
}

.popup-overlay {
    background: #00000078;
    
    &[data-popup="modal"] .popup-content {
        @apply rounded-2xl;
    }
}